import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import firebase from 'firebase';
import { ContentWrapper } from '../../../global.css';
import { Link } from '../../core/button';
import ExpandableList from '../../core/expandableList';
import { Container, Title, Content, TextContainer, LinkContainer, TermsLink } from './index.css';

const query = graphql`
  query LitUpGuidelinesQuery {
    litUpJson {
      guidelines {
        title
        cta
        url
        termsCta
        termsUrl
        questions {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

const LitUpGuidelines = () => {
  const data = useStaticQuery(query).litUpJson.guidelines;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextContainer>
            <Title>{data.title}</Title>
          </TextContainer>
        </Content>
        <ExpandableList
          data={data.questions.map(({ question, answer }) => ({ question, answer: answer.childMarkdownRemark.html }))}
        />
        <LinkContainer>
          <Link
            title={data.cta}
            href={data.url}
            onClick={() => {
              firebase.analytics().logEvent('more_faq_link_clicked');
            }}
          />
          <TermsLink href={data.termsUrl}>{data.termsCta}</TermsLink>
        </LinkContainer>
      </ContentWrapper>
    </Container>
  );
};

export default LitUpGuidelines;
