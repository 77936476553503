import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import firebase from 'firebase';
import { Link } from '../../core/button';
import { ButtonVariant } from '../../core/button/button.const';
import { Container, Title, Content, Icon } from './index.css';
import { ContentWrapper } from '../../../global.css';

const query = graphql`
  query LitpUpNewVoicesQuery {
    litUpJson {
      newVoices {
        title
        cta
        url
        image {
          childImageSharp {
            fixed(width: 148, height: 139, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;

const LitUpNewVoices = () => {
  const data = useStaticQuery(query).litUpJson.newVoices;

  return (
    <Container>
      <ContentWrapper padding={50}>
        <Content>
          <Icon fixed={data.image.childImageSharp.fixed} alt={data.title} />
          <Title>{data.title}</Title>
          <Link
            title={data.cta}
            variant={ButtonVariant.LIGHT}
            href={data.url}
            onClick={() => {
              firebase.analytics().logEvent('apply_now_link_clicked');
            }}
          />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default LitUpNewVoices;
