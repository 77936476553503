import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import {
  Container,
  Subtitle,
  Title,
  Text,
  Item,
  Icon,
  ContentTitle,
  ContentSubtitle,
  ContentsWrapper,
  Heading,
  Content,
  TextWrapper,
} from './index.css';
import { ContentWrapper } from '../../../global.css';

const query = graphql`
  query LitpUpRoadmapQuery {
    litUpJson {
      roadmap {
        title
        subtitle
        text
        heading {
          childMarkdownRemark {
            html
          }
        }
        items {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const LitUpRoadmap = () => {
  const data = useStaticQuery(query).litUpJson.roadmap;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <TextWrapper>
          <Subtitle>{data.subtitle}</Subtitle>
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
        </TextWrapper>
        <Heading dangerouslySetInnerHTML={{ __html: data.heading.childMarkdownRemark.html }} />
        <ContentsWrapper>
          {data.items.map(content => (
            <Item key={content.title}>
              <Content>
                <Icon fluid={content.image.childImageSharp.fluid} alt={content.title} />
                <ContentTitle>{content.title}</ContentTitle>
                <ContentSubtitle>{content.text}</ContentSubtitle>
              </Content>
            </Item>
          ))}
        </ContentsWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default LitUpRoadmap;
