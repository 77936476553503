import styled, { css } from 'styled-components';
import { color } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.dl`
  border-bottom: 1px solid ${color.black};
`;

export const Question = styled.dt`
  font-size: 20px;
  line-height: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:first-child) {
    border-top: 1px solid ${color.black};
  }
`;

export const QuestionText = styled.span``;

export const QuestionToggleButton = styled.button`
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
  background: none;
  border: none;
  width: 11px;
  height: 11px;
  line-height: 0;
  font-size: 0;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${color.black};
  }

  &:before {
    width: 11px;
    height: 1px;
    margin-left: -5px;
  }

  &:after {
    margin-top: -5px;
    width: 1px;
    height: 11px;
    transition: all ease-in-out 0.25s;
    will-change: transform opacity;
    opacity: ${props => (props.theme.expanded ? 0 : 1)};
    transform: ${props => (props.theme.expanded ? css`rotate(90deg)` : css`rotate(0)`)};
  }
`;

export const Answer = styled.dd`
  display: ${props => (props.theme.expanded ? 'block' : 'none')};
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 44px;

  ${MEDIA.MIN_TABLET`
    font-size: 18px;
    line-height: 28px;
    padding-left: 62px;
    padding-right: 62px;
  `};
  a {
    color: ${color.primaryCta};
  }
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style-position: inside !important;
    display: flex;
    flex-direction: column;
    list-style: initial !important;
  }
  li ul {
    padding-left: 40px;
    padding-bottom: 1em;
  }
  li::marker {
    white-space: normal;
  }
  li ul li {
    list-style: circle !important;
  }
  em {
    font-style: italic;
  }
`;
