import { graphql, useStaticQuery } from 'gatsby';
import firebase from 'firebase';
import React from 'react';
import { Link } from '../../core/button';
import { ButtonVariant } from '../../core/button/button.const';
import { Container, Title, Text, Icon, ImagesContainer, Image, Content } from './index.css';
import { ContentWrapper } from '../../../global.css';

const query = graphql`
  query LitpUpReadershipQuery {
    litUpJson {
      readership {
        icon {
          publicURL
        }
        title
        text
        cta
        url
        images {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 655, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const LitUpReadership = () => {
  const data = useStaticQuery(query).litUpJson.readership;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <Icon src={data.icon.publicURL} alt={data.title} />
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
          <Link
            title={data.cta}
            variant={ButtonVariant.LIGHT}
            href={data.url}
            onClick={() => {
              firebase.analytics().logEvent('litup_readership_link_clicked');
            }}
          />
        </Content>
      </ContentWrapper>
      <ImagesContainer>
        {data.images.map(({ alt, image }) => (
          <Image key={alt} fluid={image.childImageSharp.fluid} alt={alt} />
        ))}
      </ImagesContainer>
    </Container>
  );
};

export default LitUpReadership;
