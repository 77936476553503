import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 128px;
  padding-bottom: 83px;
  background: ${color.background};
  text-align: left;

  ${MEDIA.MIN_TABLET`
    padding-top: 120px;
    padding-bottom: 120px;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 95px;
  `};
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 36px;
  white-space: pre-wrap;
  text-transform: uppercase;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 37px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
`;

export const ContentsWrapper = styled.div`
  display: grid;
  width: 100%;
  ${MEDIA.MIN_TABLET`
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 53px;
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  ${MEDIA.TABLET`
    margin-bottom: 60px;
  `};
`;

export const Icon = styled(Img)`
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  margin-bottom: 40px;
`;

export const ContentTitle = styled.h5`
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 40px;
  `};
`;

export const ContentSubtitle = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.div`
  h2 {
    font-size: 30px;
    line-height: 28px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
    span {
      margin-top: 4px;
      height: 28px;
      width: 84px;
      margin-left: 5px !important;
      margin-right: 10px !important;
      img {
        box-shadow: inset 0px 0px 0px 400px ${color.background} !important;
      }
    }
  }
  ${MEDIA.MIN_TABLET`
    h2 {
      font-size: 40px;
      line-height: 28px;
      margin-bottom: 78px;
      span {
        margin-top: 0;
        height: 36px;
        width: 109px;
      }
    }
  `}
`;
