import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import firebase from 'firebase';
import { ContentWrapper } from '../../../global.css';
import { Link } from '../../core/button';
import { ButtonVariant } from '../../core/button/button.const';
import { Container, Title, Text, Content, TextWrapper, Subtitle, Image, Info } from './index.css';

const query = graphql`
  query LitUpHeroQuery {
    litUpJson {
      hero {
        title
        subtitle
        text
        info
        cta
        url
        image {
          childImageSharp {
            fluid(maxWidth: 550, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const LitUpHero = () => {
  const data = useStaticQuery(query).litUpJson.hero;

  return (
    <Container>
      <ContentWrapper padding={30}>
        <Content>
          <TextWrapper>
            <Subtitle>{data.subtitle}</Subtitle>
            <Title>{data.title}</Title>
            <Text>{data.text}</Text>
            <Link
              title={data.cta}
              variant={ButtonVariant.LIGHT}
              href={data.url}
              onClick={() => {
                firebase.analytics().logEvent('apply_now_link_clicked');
              }}
            />
            <Info>{data.info}</Info>
          </TextWrapper>
          <Image fluid={data.image.childImageSharp.fluid} alt={data.title} />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default LitUpHero;
