import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.textInverted};
  padding-top: 80px;
  background: ${color.readership};
  ${MEDIA.MIN_TABLET`
    padding-top: 114px;
  `};
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 44px;
  text-transform: uppercase;
  max-width: 500px;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 37px;
  `};
`;

export const Text = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  ${MEDIA.MIN_TABLET`
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 43px;
  `};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  ${MEDIA.TABLET`
    margin-bottom: 50px;
  `};
`;

export const Icon = styled.img`
  width: 148px;
  height: 94px;
  margin-bottom: 40px;
  ${MEDIA.MIN_TABLET`
    width: 122px;
    height: 80px;
    margin-bottom: 62px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 671px;
  margin: 0 auto;
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 80px;
  ${MEDIA.MIN_TABLET`
    margin-top: 82px;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const Image = styled(Img)``;
