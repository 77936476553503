import styled, { css } from 'styled-components';
import { color } from '../../../constants/theme';
import { ButtonVariant } from './button.const';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 50px;
  background-color: ${props => (props.variant === ButtonVariant.DARK ? color.secondaryCta : color.background)};
  border-radius: 25px;
  color: ${props => (props.variant === ButtonVariant.DARK ? color.background : color.black)};
  border: 0;
  text-decoration: none;
`;

export const StyledButton = styled.button`
  ${styles}
`;

export const StyledLink = styled.a`
  ${styles}
`;
