import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Container, Title, Text, Icon, TextWrapper, PartnersWrapper, Partner, PartnerText } from './index.css';
import { ContentWrapper } from '../../../global.css';

const query = graphql`
  query LitpUpPartnersQuery {
    litUpJson {
      partners {
        title
        text
        items {
          title
          text
          image {
            childImageSharp {
              fluid(maxWidth: 150, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const LitUpPartners = () => {
  const data = useStaticQuery(query).litUpJson.partners;

  return (
    <Container>
      <ContentWrapper padding={0}>
        <TextWrapper>
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
        </TextWrapper>
        <PartnersWrapper>
          {data.items.map(content => (
            <Partner key={content.title}>
              <Icon
                imgStyle={{ objectFit: 'contain' }}
                fluid={content.image.childImageSharp.fluid}
                alt={content.title}
              />
              <PartnerText>{content.text}</PartnerText>
            </Partner>
          ))}
        </PartnersWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default LitUpPartners;
