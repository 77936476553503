import React from 'react';
import { ButtonVariant } from './button.const';
import { StyledButton, StyledLink } from './index.css';

export const Button = ({ title, variant = ButtonVariant.DARK, onClick = () => null }) => {
  return <StyledButton variant={variant}>{title}</StyledButton>;
};

export const Link = ({ title, href, variant = ButtonVariant.DARK, onClick = () => null }) => {
  return (
    <StyledLink variant={variant} href={href} onClick={onClick}>
      {title}
    </StyledLink>
  );
};
