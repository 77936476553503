import React from 'react';
import Layout from 'components/layout';
import Hero from '../components/litUp/hero';
import Roadmap from '../components/litUp/roadmap';
import Readership from '../components/litUp/readership';
import Guidelines from '../components/litUp/guidelines';
import Partners from '../components/litUp/partners';
import NewVoices from '../components/litUp/newVoices';

const Index = () => (
  <Layout>
    <Hero />
    <Roadmap />
    <Readership />
    <Guidelines />
    <Partners />
    <NewVoices />
  </Layout>
);

export default Index;
