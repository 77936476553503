import firebase from 'firebase';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Container, Answer, Question, QuestionToggleButton, QuestionText } from './index.css';

const Item = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ThemeProvider theme={{ expanded }}>
      <>
        <Question
          onClick={() => {
            setExpanded(expanded => !expanded);
            if (!expanded) {
              firebase.analytics().logEvent('faq_question_open');
            }
          }}
        >
          <QuestionText>{question}</QuestionText>

          <QuestionToggleButton
            aria-expanded={expanded}
            aria-label={question}
            onClick={e => {
              e.stopPropagation();
              setExpanded(expanded => !expanded);
            }}
          />
        </Question>
        <Answer
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
          aria-expanded={expanded}
          aria-hidden={!expanded}
        />
      </>
    </ThemeProvider>
  );
};

const ExpandableList = ({ data }) => (
  <Container>
    {data.map(({ question, answer }) => (
      <Item key={question} question={question} answer={answer} />
    ))}
  </Container>
);

Item.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default ExpandableList;
