import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 100px;
  padding-bottom: 40px;
  background: ${color.secondaryBg};
  padding-left: 30px;
  padding-right: 30px;

  ${MEDIA.MIN_TABLET`
    padding-top: 96px;
    padding-bottom: 59px;
    padding-left: 0;
    padding-right: 0;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 95px;
  `};
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 36px;
  white-space: pre-wrap;
  text-transform: uppercase;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 30px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  max-width: 579px;
`;

export const PartnersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Partner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 286px;
  min-height: 164px;
  justify-content: space-between;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 60px;
`;

export const Icon = styled(Img)`
  width: 100%;
  max-width: 150px;
  max-height: 48px;
`;

export const PartnerText = styled.p`
  padding-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
`;
