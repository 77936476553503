import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.textInverted};
  background: ${color.purple};
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  ${MEDIA.MIN_TABLET`
    padding-top: 54px;
    padding-bottom: 66px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: ${font.secondary};
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 36px;
  white-space: pre-wrap;
  text-transform: uppercase;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 37px;
  `};
`;

export const Icon = styled(Img)`
  margin-bottom: 40px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 25px;
  `};
`;
