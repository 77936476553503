import styled from 'styled-components';
import { color } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 100px;
  background: ${color.secondary};

  ${MEDIA.MIN_TABLET`
    padding-top: 120px;
  `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 34px;
  width: 100%;
  margin-bottom: 40px;

  ${MEDIA.MIN_TABLET`
    font-size: 40px;
    margin-bottom: 80px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 50px;
  max-width 500px;
  ${MEDIA.MIN_TABLET`
    margin-bottom: 40px;
  `};
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 109px;
`;

export const TermsLink = styled.a`
  color: ${color.primaryCta};
  margin-top: 10px;
  font-size: 14px;
  line-height: 26px;
`;
