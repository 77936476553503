import styled from 'styled-components';
import { color, font } from '../../../constants/theme';
import MEDIA from '../../../helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  color: ${color.black};
  padding-top: 132px;
  padding-bottom: 80px;
  text-align: left;
  background: linear-gradient(180deg, rgba(167, 169, 246, 0.99) 0%, #daf1e8 100%);

  ${MEDIA.MIN_TABLET`
    padding-top: 199px;
    padding-bottom: 80px;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${MEDIA.MIN_TABLET`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `};
`;

export const TextWrapper = styled.div`
  padding-bottom: 60px;
  ${MEDIA.MIN_TABLET`
    min-width: 410px;
    padding-bottom: 0;
  `};
`;

export const Subtitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 0.1em;
`;

export const Title = styled.h1`
  font-family: ${font.secondary};
  font-size: 40px;
  line-height: 45px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
  white-space: pre-wrap;

  ${MEDIA.MIN_TABLET`
    font-size: 60px;
    line-height: 60px;
  `};
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 40px;
  white-space: pre-wrap;
`;

export const Image = styled(Img)`
  width: 100%;
`;

export const Info = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 29px;
  white-space: pre-wrap;
`;
